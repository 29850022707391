import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IJoinWebReceiverResponse } from "src/app/common/services/interfaces/ijoin-web-receiver-response";
import { IMessage } from "src/app/common/services/interfaces/imessage-model";
import { AuthService } from "./auth.service";
import { IBrandingModel } from "./interfaces/ibranding-model";
import { IPatientDetails } from "./interfaces/ipatient-verification";
import { IMessageReaction } from "./interfaces/ireaction-response";
import { ParametersService } from "./parameters.service";
import { ISurveyQuestionsResponse } from "./interfaces/isurvey-questions-response";
import { ISurveyResponse } from "./interfaces/isurvey-response";

@Injectable({
	providedIn: "root",
})
export class EwrService {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly parameters: ParametersService,
		private readonly auth: AuthService,
	) { }

	/**
	 * Retrieves the hospital branding information.
	 * @returns An Observable of type IBrandingModel.
	 */
	getHospitalBranding(): Observable<IBrandingModel> {
		const facilityId: string | null = this.parameters.getFacilityId();
		const brandingType: string = this.parameters.getBrandingType();

		return this.httpClient.get<IBrandingModel>(`/api/v2/receiver/facilities/${facilityId}/${brandingType}/branding`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Anonymous",
			},
			responseType: "json",
		});
	}

	getHospitalBrandingURL(): Observable<string> {
		const facilityId: string | null = this.parameters.getFacilityId();
		const brandingType: string = this.parameters.getBrandingType();

		return this.httpClient.get(`/api/v2/receiver/facilities/${facilityId}/${brandingType}/branding`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Anonymous",
			},
			responseType: "text",
		});
	}

	/**
	 * Retrieves the patient details.
	 * @returns An Observable of type IPatientDetails.
	 */
	getPatientDetails(): Observable<IPatientDetails> {
		const receiverId: string | null = this.parameters.getReceiverId();

		return this.httpClient.get<IPatientDetails>(`/api/v2/receiver/${receiverId}/getpatientdetails`, {
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Anonymous",
			},
			responseType: "json",
		});
	}

	/**
	 * Retrieves the messages.
	 * @returns An Observable of type IMessage[].
	 */
	getMessages(): Observable<IMessage[]> {
		let receiverID: string | null = this.parameters.getReceiverId();
		if (receiverID === null) {
			return of([]);
		}

		return this.httpClient.get<IMessage[]>(`/api/v2/receiver/procedure/${this.parameters.getProcedureId()}/messages`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Basic ${this.auth.getCredentials(receiverID)}`,
			},
			responseType: "json",
		});
	}

	/**
	 * Retrieves the latest message.
	 * @returns An Observable of type IMessage.
	 */
	getLatestMessage(): Observable<IMessage> {
		let receiverID: string | null = this.parameters.getReceiverId();
		if (receiverID === null) {
			return of();
		}

		return this.httpClient.get<IMessage>(`/api/v2/receiver/procedure/${this.parameters.getProcedureId()}/messages/latest`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Basic ${this.auth.getCredentials(receiverID)}`,
			},
			responseType: "json",
		});
	}

	/**
	 * Posts a message reaction.
	 * @param messageReaction The message reaction to be posted.
	 * @returns An Observable of type never.
	 */
	messageReaction(messageReaction: IMessageReaction): Observable<never> {
		let receiverID: string | null = this.parameters.getReceiverId();
		if (receiverID === null) {
			return of();
		}

		return this.httpClient.post<never>(`/api/v1/reaction`, messageReaction, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Basic ${this.auth.getCredentials(receiverID)}`,
			},
			responseType: "json",
		});
	}

	/**
	 * Retrieves the reactions.
	 * @returns An Observable of type IMessageReaction[].
	 */
	getReactions(): Observable<IMessageReaction[]> {
		let receiverID: string | null = this.parameters.getReceiverId();
		if (receiverID === null) {
			return of([]);
		}

		return this.httpClient.get<IMessageReaction[]>(`/api/v2/receiver/${this.parameters.getReceiverId()}/reaction`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Basic ${this.auth.getCredentials(receiverID)}`,
			},
			responseType: "json",
		});
	}

	/**
	 * Joins the web receiver.
	 * @param familyName The patient's family name.
	 * @param phoneNumber The patient's phone number.
	 * @returns An Observable of type IJoinWebReceiverResponse.
	 */
	joinWebReceiver(familyName: string, phoneNumber: string): Observable<IJoinWebReceiverResponse> {
		return this.httpClient.put<IJoinWebReceiverResponse>('/api/v2/receiver/joinwebreceiver', { "patientFamilyName": familyName, "phoneNumber": phoneNumber, "receiverId": this.parameters.getReceiverId() });
	}

	getSurveyQuestions(procedureId: number): Observable<ISurveyQuestionsResponse> {
		let receiverID: string | null = this.parameters.getReceiverId();
		if (receiverID === null) {
			return of();
		}

		return this.httpClient.get<ISurveyQuestionsResponse>(`/api/v2/procedures/${procedureId}/surveyquestions`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Basic ${this.auth.getCredentials(receiverID)}`,
			},
			responseType: "json",
		});
	}

	submitSurveyAnswers(answers: ISurveyResponse): Observable<any> {
		let receiverID: string | null = this.parameters.getReceiverId();
		if (receiverID === null) {
			return of();
		}

		return this.httpClient.post(`/api/v2/procedures/survey`, answers, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Basic ${this.auth.getCredentials(receiverID)}`,
			},
			responseType: "json",
		});
	}
}
