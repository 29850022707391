<div class="survey-container">
	<mat-toolbar #barTop class="bar-top">
		<div #surveyNameDiv class="survey-name">{{ surveyName }}</div>
	</mat-toolbar>
	<div #surveyQuestionsContainer class="survey-questions-container">
		<ng-container *ngIf="surveyQuestions.length > 0">
			<form class="survey-form" [formGroup]="surveyForm">
				<div *ngFor="let question of surveyQuestions">
					<div class="survey-question column full-center">
						<label class="survey-question-label">{{ question.question }}</label>
						<div class="survey-question-answer">
							<div
								class="survey-rating-container"
								*ngIf="
									questionTypeIDs.rating.includes(question.displayOrder) &&
									question.active &&
									!question.hasFreeText
								">
								<mat-label>Rate</mat-label>
								<mat-select
									class="survey-rating"
									*ngIf="
										questionTypeIDs.rating.includes(question.displayOrder) &&
										question.active &&
										!question.hasFreeText
									"
									[formControlName]="question.questionId"
									panelClass="fullscreen-select-panel">
									<div class="select-pane-header">Rating</div>
									<mat-option
										*ngFor="
											let option of getNumberRange(
												questionOptions.rating.min,
												questionOptions.rating.max
											)
										"
										[value]="option">
										{{ option }}
									</mat-option>
								</mat-select>
								<mat-icon class="custom-select-arrow">
									keyboard_arrow_down
								</mat-icon>
							</div>
							<mat-radio-group
								class="radio-group"
								*ngIf="
									questionTypeIDs.sentiment.includes(question.displayOrder) &&
									question.active &&
									!question.hasFreeText
								"
								(change)="onRadioChange(question.questionId)"
								[formControlName]="question.questionId">
								<div
									class="radio-container"
									*ngFor="let option of questionOptions.sentiment">
									<div class="radio-content">
										<label class="radio-label">{{ option }}</label>
										<mat-radio-button
											labelPosition="before"
											[value]="option"
											[disableRipple]="true"></mat-radio-button>
									</div>
									<div class="radio-divider"></div>
								</div>
							</mat-radio-group>
							<mat-radio-group
								class="radio-group"
								*ngIf="
									questionTypeIDs.boolean.includes(question.displayOrder) &&
									question.active &&
									!question.hasFreeText
								"
								(change)="onRadioChange(question.questionId)"
								[formControlName]="question.questionId">
								<div
									class="radio-container"
									*ngFor="let option of questionOptions.boolean">
									<div class="radio-content">
										<label class="radio-label">{{ option }}</label>
										<mat-radio-button
											labelPosition="before"
											[value]="option"
											[disableRipple]="true"></mat-radio-button>
									</div>
									<div class="radio-divider"></div>
								</div>
							</mat-radio-group>
							<div
								*ngIf="
									questionTypeIDs.text.includes(question.displayOrder) &&
									question.active &&
									question.hasFreeText
								">
								<label class="survey-textarea-label">Comment</label>
								<textarea
									matInput
									[formControlName]="question.questionId"
									class="survey-textarea"></textarea>
							</div>
						</div>
						<div
							class="rating-key"
							*ngIf="
								questionTypeIDs.rating.includes(question.displayOrder) &&
								question.active &&
								!question.hasFreeText
							">
							1 = Extremely Unsatisfied. 10 = Extremely Satisfied
						</div>
					</div>
				</div>
				<button
					#submitButton
					mat-raised-button
					class="submit-button"
					(click)="onSubmitSurvey()"
					[disabled]="!surveyForm.valid || surveySubmitted">
					Submit
				</button>
				<app-footer #footer></app-footer>
			</form>
		</ng-container>
	</div>
</div>
