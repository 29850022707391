import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { EwrService } from 'src/app/common/services/ewr.service';
import { ISurveyAnswerDetail } from 'src/app/common/services/interfaces/isurvey-answer-detail';
import { ISurveyQuestionDetail } from 'src/app/common/services/interfaces/isurvey-question-detail';
import { ISurveyQuestionsResponse } from 'src/app/common/services/interfaces/isurvey-questions-response';
import { ISurveyResponse } from 'src/app/common/services/interfaces/isurvey-response';
import { EaseLoggerService } from 'src/app/common/services/logger/ease-logger.service';
import { LogLevel } from 'src/app/common/services/logger/log-level';
import { ParametersService } from 'src/app/common/services/parameters.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrl: './survey.component.css'
})
export class SurveyComponent implements OnInit {
  @ViewChild('surveyNameDiv', { read: ElementRef, static: false }) surveyNameDiv!: ElementRef;
  surveyQuestionsResponse: ISurveyQuestionsResponse | null = null;
  surveyQuestions: ISurveyQuestionDetail[] = [];
  surveyName: string = 'Survey';
  facilitySurveyId: number = 0;
  surveySubmitted: boolean = false;

  questionTypes = {
    1: 'rating',
    2: 'sentiment',
    3: 'boolean',
    4: 'text',
  };

  questionTypeIDs = {
    'rating': [1],
    'sentiment': [2, 3, 4],
    'boolean': [5],
    'text': [6],
  };

  questionOptions = {
    'rating': {
      min: 1,
      max: 10,
    },
    'sentiment': [
      'Strongly Agree',
      'Neutral',
      'Strongly Disagree',
    ],
    'boolean': [
      'Yes',
      'No',
    ],
  };

  surveyForm: FormGroup;

  constructor(
    private readonly ewr: EwrService,
    private readonly parameters: ParametersService,
    private readonly logger: EaseLoggerService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.surveyForm = this.fb.group({
      questionControl: ['']
    });
  }

  ngOnInit(): void {
    const procedureId: number | null = this.parameters.getProcedureId();

    if (procedureId !== null) {
      this.ewr.getSurveyQuestions(procedureId).pipe(
        catchError(error => {
          let redirectUrl: string = `/invite/${this.parameters.getFacilityName()}/${this.parameters.getFacilityId()}/${this.parameters.getReceiverId()}`;
          this.router.navigate([redirectUrl]);
          return [];
        })
      ).subscribe(response => {
        this.surveyQuestionsResponse = response;
        this.surveyQuestions = response.questions;
        this.facilitySurveyId = response.facilitySurveyId;
        this.surveyNameDiv.nativeElement.innerText = response.facilitySurveyTitle;

        this.surveyQuestions.forEach(question => {
          this.surveyForm.addControl(question.questionId.toString(), new FormControl('', Validators.required));
        });
      }
      );
    } else {
      this.logger.log('Procedure ID is null.', LogLevel.WARN);
      let redirectUrl: string = `/invite/${this.parameters.getFacilityName()}/${this.parameters.getFacilityId()}/${this.parameters.getReceiverId()}`;
      this.router.navigate([redirectUrl]);
    }
  }

  // Run the code on view update
  ngAfterViewChecked(): void {
    this.updateRadioTouchTarget();
  }

  getNumberRange(min: number, max: number): number[] {
    return Array.from({ length: max - min + 1 }, (_, i) => i + min).reverse();
  }

  onSubmitSurvey(): void {
    this.surveySubmitted = true;
    let procedureId = this.parameters.getProcedureId();

    const surveyAnswers: ISurveyAnswerDetail[] = [];

    if (procedureId !== null) {
      if (this.surveyForm.valid) {
        const formValue = this.surveyForm.value;

        const surveyAnswers: ISurveyAnswerDetail[] = Object.keys(formValue)
          .filter(key => !isNaN(parseInt(key, 10)))
          .map(key => ({
            questionId: parseInt(key, 10),
            answer: formValue[key]
          }));

        const surveyResponse: ISurveyResponse = {
          procedureId: procedureId,
          surveyAnswerList: surveyAnswers,
          facilitySurveyId: this.facilitySurveyId,
        };

        this.ewr.submitSurveyAnswers(surveyResponse).subscribe(response => {
          this.logger.log('Survey submitted.', LogLevel.INFO);
          this.parameters.setSurveySubmitted(true);

          let redirectUrl: string = `/invite/${this.parameters.getFacilityName()}/${this.parameters.getFacilityId()}/${this.parameters.getReceiverId()}`;
          this.router.navigate([redirectUrl]);
        });
      }
    }
  }

  onRadioChange(questionId: number): void {
    // Get the control value associated with the questionId
    const controlValue = this.surveyForm.controls[questionId]?.value;

    // Find all elements with the class 'radio-container'
    const radioGroups = document.querySelectorAll(`[ng-reflect-name="${questionId}"]`);
    const radioContainers = radioGroups.length > 0 ? radioGroups[0].querySelectorAll('.radio-container') : [];

    // Iterate over each radio-container
    radioContainers.forEach(container => {
      const radioButton = container.querySelector('mat-radio-button');

      // If the radio button's value matches the control value, add the class
      if (radioButton && radioButton.getAttribute('ng-reflect-value') === controlValue) {
        container.classList.add('selected-radio-container');
      } else {
        // Remove the class if it doesn't match
        container.classList.remove('selected-radio-container');
      }
    });
  }

  updateRadioTouchTarget(): void {
    let radioTarget;
    const intervalId = setInterval(() => {
      radioTarget = document.querySelector('.radio-container');
      if (radioTarget !== undefined) {
        clearInterval(intervalId);
        const radioTargetWidth = radioTarget?.clientWidth;
        document.documentElement.style.setProperty('--radio-touch-target-width', `${radioTargetWidth}px`);
      }
    }, 250);
  }
}
