import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SplashScreenComponent } from "./pages/splash-screen/splash-screen.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgOptimizedImage } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { TimePipe } from "./common/utils/time.pipe";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeadersInterceptorService } from "./common/services/headers-interceptor.service";
import { ErrorInterceptorService } from "./common/services/error-interceptor.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NgxTranslateModule } from "./common/translate/translate.module";
import { SnackbarCookiesComponent } from "./common/components/snackbar-cookies/snackbar-cookies.component";
import { BrandingComponent } from "./common/components/branding/branding.component";
import { EmojiBtnComponent } from "./common/components/emoji-btn/emoji-btn.component";
import { FooterComponent } from "./common/components/footer/footer.component";
import { SnackbarPatientDischargedComponent } from "./common/components/snackbar-notification/snackbar-patient-discharged.component";
import { SnackbarPatientNotFoundComponent } from "./common/components/snackbar-notification/snackbar-patient-not-found.component";
import { SnackbarPatientNotRegisteredComponent } from "./common/components/snackbar-notification/snackbar-patient-not-resgitered.component";
import { SnackbarErrorComponent } from "./common/components/snackbar-notification/snackbar-error.component";
import { ApiUrlInterceptorService } from "./common/services/api-url-interceptor.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { VerificationComponent } from "./pages/verification/verification.component";
import { ReactiveFormsModule } from "@angular/forms";
import { VerificationFormComponent } from './common/components/verification-form/verification-form.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { ThreadBarComponent } from './common/components/thread-bar/thread-bar.component';
import { ThreadChatComponent } from './common/components/thread-chat/thread-chat.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ThreadComponent } from './pages/thread/thread.component';
import { ThreadMessageComponent } from './common/components/thread-message/thread-message.component';
import { ThreadFullScreenMediaComponent } from './common/components/thread-full-screen-media/thread-full-screen-media.component';
import { ThreadNewMessageNotificationComponent } from './common/components/thread-new-message-notification/thread-new-message-notification.component'
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormField } from "@angular/material/input";
import { SurveyComponent } from "./pages/survey/survey/survey.component";

/**
 * The main module of the application.
 * This module is responsible for declaring and bootstrapping the root component,
 * importing necessary modules, and providing services and interceptors.
 */
@NgModule({
    declarations: [
        AppComponent,
        SplashScreenComponent,
        BrandingComponent,
        TimePipe,
        EmojiBtnComponent,
        VerificationComponent,
        FooterComponent,
        SnackbarCookiesComponent,
        SnackbarPatientDischargedComponent,
        SnackbarPatientNotFoundComponent,
        SnackbarPatientNotRegisteredComponent,
        VerificationFormComponent,
        ThreadBarComponent,
        ThreadChatComponent,
        ThreadComponent,
        ThreadMessageComponent,
        ThreadFullScreenMediaComponent,
        ThreadNewMessageNotificationComponent,
        SnackbarErrorComponent,
        SurveyComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        NgOptimizedImage,
        MatButtonModule,
        MatIconModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        NgxTranslateModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatToolbarModule,
        MatDividerModule,
        MatCheckboxModule,
        MatRadioModule,
        MatInputModule,
        MatSelectModule,
        MatFormField,
    ],
    providers: [
        /**
         * Provides the HTTP interceptors for the application.
         * The `HeadersInterceptorService` intercepts HTTP requests and adds headers.
         * The `ApiUrlInterceptorService` intercepts HTTP requests and modifies the API URL.
         * The `ErrorInterceptorService` intercepts HTTP responses and handles errors.
         */
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeadersInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiUrlInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true,
        },

        /**
         * Provides the HTTP client with interceptors from the dependency injection container.
         */
        provideHttpClient(withInterceptorsFromDi()),
        DeviceDetectorService,

        /**
         * Provides the configuration for the `ngx-mask` library.
         */
        provideEnvironmentNgxMask(),
    ],
})
export class AppModule { }
