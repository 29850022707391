import { Injectable } from '@angular/core';
import { IMessageReactionState } from './interfaces/imessage-reaction-state';
import { IReceiverData } from 'src/app/common/services/interfaces/ireceiver-data';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  private brandingType: string = "ease web receiver";
  private newMessageIdKey = 'newMessageId';
  private receiverIdKey = 'receiverId';
  private receiverId: number | null = null;
  private reactionStates: Map<number, IMessageReactionState> = new Map<number, IMessageReactionState>();

  constructor() { }

  getReceiverData(): IReceiverData | null {
    if (this.receiverId === null) {
      return null;
    }

    let receiverDataString = localStorage.getItem(this.receiverId.toString());

    if (receiverDataString === null) {
      return null;
    }

    let receiverData: IReceiverData = JSON.parse(receiverDataString);

    return receiverData;
  }

  setReceiverData(receiverData: IReceiverData): void {
    if (this.receiverId === null) {
      return;
    }

    localStorage.setItem(this.receiverId.toString(), JSON.stringify(receiverData));
  }

  /**
   * Sets the facility ID in local storage.
   * @param facilityId The facility ID to set.
   */
  setFacilityId(facilityId: number) {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return;
    }

    receiverData.facilityId = facilityId;

    this.setReceiverData(receiverData);
  }

  /**
   * Sets the procedure ID in local storage.
   * @param procedureId The procedure ID to set.
   */
  setProcedureId(procedureId: number) {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return;
    }

    receiverData.procedureId = procedureId;

    this.setReceiverData(receiverData);
  }

  /**
   * Sets the patient's given name in local storage.
   * @param name The given name to set.
   */
  setPatientGivenName(name: string) {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return;
    }

    receiverData.patientGivenName = name;

    this.setReceiverData(receiverData);
  }

  /**
   * Sets the patient's family name in local storage.
   * @param name The family name to set.
   */
  setPatientFamilyName(name: string) {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return;
    }

    receiverData.patientFamilyName = name;

    this.setReceiverData(receiverData);
  }

  /**
   * Sets the facility name in local storage.
   * @param name The facility name to set.
   */
  setFacilityName(name: string) {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return;
    }

    receiverData.facilityName = name;

    this.setReceiverData(receiverData);
  }

  /**
   * Sets the receiver ID in local storage.
   * @param receiverId The receiver ID to set.
   */
  setReceiverId(receiverId: number) {
    this.receiverId = receiverId;
    sessionStorage.setItem(this.receiverIdKey, receiverId.toString());
  }

  setSurveySubmitted(surveySubmitted: boolean) {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return;
    }

    receiverData.surveySubmitted = surveySubmitted;

    this.setReceiverData(receiverData);
  }

  /**
   * Sets the reaction state for a message.
   * @param messageId The ID of the message.
   * @param reactionName The name of the reaction.
   * @param reactionState The state of the reaction.
   */
  setMessageReactionState(messageId: number, reactionName?: string, reactionState?: boolean) {
    if (!this.reactionStates.has(Number(messageId))) {
      this.reactionStates.set(Number(messageId), { heart: false, thumb: false });
    }

    if (reactionName !== undefined && reactionState !== undefined) {
      let currentReactionState: IMessageReactionState | undefined = this.reactionStates.get(messageId);
      if (currentReactionState !== undefined) {
        switch (reactionName) {
          case "heart":
            currentReactionState!.heart = reactionState;
            break;
          case "thumb":
            currentReactionState!.thumb = reactionState;
            break;
          default:
            break;
        }

        this.reactionStates.set(messageId, currentReactionState);
      }
    }
  }

  /**
   * Sets the new message ID in local storage.
   * @param messageId The new message ID to set.
   */
  setNewMessageId(messageId: number) {
    localStorage.setItem(this.newMessageIdKey, messageId.toString());
  }

  /**
   * Gets the facility ID from local storage.
   * @returns The facility ID, or null if not found.
   */
  getFacilityId(): number | null {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return null;
    }

    return (receiverData.facilityId !== null) ? receiverData.facilityId : null;
  }

  /**
   * Gets the procedure ID from local storage.
   * @returns The procedure ID, or null if not found.
   */
  getProcedureId(): number | null {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return null;
    }

    return (receiverData.procedureId !== null) ? receiverData.procedureId : null;
  }

  /**
   * Gets the branding type.
   * @returns The branding type.
   */
  getBrandingType(): string {
    return this.brandingType;
  }

  /**
   * Gets the patient's given name from local storage.
   * @returns The given name, or null if not found.
   */
  getPatientGivenName(): string | null {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return null;
    }

    return receiverData.patientGivenName;
  }

  /**
   * Gets the patient's family name from local storage.
   * @returns The family name, or null if not found.
   */
  getPatientFamilyName(): string | null {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return null;
    }

    return receiverData.patientFamilyName;
  }

  /**
   * Gets the facility name from local storage.
   * @returns The facility name, or null if not found.
   */
  getFacilityName(): string | null {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return null;
    }

    return receiverData.facilityName;
  }

  /**
   * Gets the receiver ID from local storage.
   * @returns The receiver ID.
   */
  getReceiverId(): number | null {
    let id: string | null = sessionStorage.getItem(this.receiverIdKey);
    return (id !== null) ? parseInt(id) : null;;
  }

  getSurveySubmitted(): boolean {
    let receiverData = this.getReceiverData();

    if (receiverData === null) {
      return false;
    }

    return receiverData.surveySubmitted;
  }

  /**
   * Gets the reaction state for a message.
   * @param messageId The ID of the message.
   * @param reactionName The name of the reaction.
   * @returns The state of the reaction.
   */
  getMessageReactionState(messageId: number, reactionName: string): boolean {
    const currentReactionState: IMessageReactionState | undefined = this.reactionStates.get(messageId);
    if (currentReactionState !== undefined) {
      switch (reactionName) {
        case "heart":
          return currentReactionState.heart;
        case "thumb":
          return currentReactionState.thumb;
        default:
          break;
      }
    }

    return false;
  }

  /**
   * Gets the new message ID from local storage and removes it.
   * @returns The new message ID, or null if not found.
   */
  getNewMessageId(): number | null {
    let id: string | null = localStorage.getItem(this.newMessageIdKey);
    if (id !== null) {
      localStorage.removeItem(this.newMessageIdKey);
      return parseInt(id);
    }

    return null;
  }

  /**
   * Clears the parameters from storage.
   */
  clearParameters(): void {
    localStorage.removeItem(this.receiverId!.toString());
    sessionStorage.removeItem(this.receiverIdKey);
    localStorage.removeItem(this.newMessageIdKey);
  }
}
