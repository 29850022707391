<div class="container column full-center">
	<!-- Branding -->
	<div class="row" id="app-branding-row" *ngIf="showLogo">
		<app-branding
			[logoHeight]="'auto'"
			[logoWidth]="'auto'"
			(logoStatus)="handleLogoStatus($event)" />
	</div>

	<!-- Welcome Text -->
	<div
		class="row"
		id="text-section"
		[ngClass]="{ 'image-loaded': showLogo }"
		*ngIf="patientDetailsLoaded && isActive && isInvited && !surveySubmitted">
		<h5 id="welcome-text">{{ welcomeText.title | translate | titlecase }}</h5>
		<p id="get-started-text">
			{{ welcomeText.subtitle | translate }}
		</p>
	</div>

	<!-- Session Ended Text -->
	<div
		class="row"
		id="text-section"
		[ngClass]="{ 'image-loaded': showLogo }"
		*ngIf="patientDetailsLoaded && !isActive && !surveySubmitted"
		style="margin-bottom: auto">
		<h5 id="welcome-text">
			{{ welcomeText.sessionEndedTitle | translate | titlecase }}
		</h5>
		<p id="get-started-text">
			{{ welcomeText.sessionEndedText | translate }}
		</p>
	</div>

	<!-- Disinvited Text -->
	<div
		class="row"
		id="text-section"
		[ngClass]="{ 'image-loaded': showLogo }"
		*ngIf="patientDetailsLoaded && isActive && !isInvited && !surveySubmitted"
		style="margin-bottom: auto">
		<h5 id="welcome-text">
			{{ welcomeText.disinvitedTitle | translate | titlecase }}
		</h5>
		<p id="get-started-text">
			{{ welcomeText.disinvitedText | translate }}
		</p>
	</div>

	<!-- Survey Submitted Text -->
	<div
		class="row"
		id="text-section"
		[ngClass]="{ 'image-loaded': showLogo }"
		*ngIf="patientDetailsLoaded && surveySubmitted"
		style="margin-bottom: auto">
		<h5 id="welcome-text">
			{{ welcomeText.surveySubmittedTitle | translate | titlecase }}
		</h5>
		<p id="get-started-text">
			{{ welcomeText.surveySubmittedText | translate }}
		</p>
	</div>

	<!-- Survey Button -->
	<div class="row" id="button-section" *ngIf="patientDetailsLoaded && !isActive && !surveySubmitted && showSurveyButton">
		<div class="button-center-wrapper">
			<button
				class="btn-survey"
				(click)="navigateToSurvey()"
				>
				{{ "Take Our Survey" | uppercase }}
			</button>
		</div>
		</div>

	<!-- Disclaimer Section -->
	<div class="row" id="button-section" *ngIf="isActive && isInvited && !surveySubmitted">
		<div id="disclaimer-wrapper" class="full-center">
			<p
				id="disclaimer-text"
				[innerHTML]="welcomeText.disclaimer | translate"></p>
			<mat-checkbox
				[ngModel]="!!checked"
				inputId="binary"
				(ngModelChange)="checked = $event" />
		</div>
		<div class="button-center-wrapper">
			<button
				class="btn-continue"
				(click)="navigateToVerification()"
				[ngClass]="{ disabled: !checked }">
				{{ welcomeText.button | translate | uppercase }}
			</button>
		</div>

		<div class="button-center-wrapper" *ngIf="isActive && isInvited && !surveySubmitted">
			<a
				class="btn-store"
				href="https://apps.apple.com/us/app/ease-applications-messaging/id838601897"
				target="_blank"
				*ngIf="deviceInfo.os == 'iOS' || isDesktop">
				<img
					src="assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
					alt="Apple Store Button"
					(click)="copyAccessCode()" />
			</a>
			<a
				class="btn-store"
				href="https://play.google.com/store/apps/details?id=com.easeapplications.receiver"
				target="_blank"
				*ngIf="deviceInfo.os == 'Android' || isDesktop">
				<img
					src="assets/images/GetItOnGooglePlay_Badge_Web_color_English.png"
					alt="Google Store Button"
					(click)="copyAccessCode()" />
			</a>
		</div>
		<p id="access-code" *ngIf="isActive && isInvited && !surveySubmitted">
			{{ "Access Code: " | translate | uppercase }}{{ accessCode }}
		</p>
	</div>
	<app-footer></app-footer>
</div>
