import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { ParametersService } from '../services/parameters.service';

/**
 * A guard that checks if the user is authorized to access a route.
 * If the user is authenticated, it redirects to the '/thread' route.
 * If the user is not authenticated, it allows access to the route.
 *
 * @param route - The activated route snapshot.
 * @param state - The router state snapshot.
 * @returns A boolean indicating whether the user is authorized to access the route.
 */
export const authorizationGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const parameters = inject(ParametersService);

  const receiverId: string | null = route.paramMap.get('receiverId');
  if (receiverId) {
    parameters.setReceiverId((receiverId));
  } else {
    return false;
  }

  if (authService.isAuthenticated(receiverId)) {
    let redirectUrl: string = `/messages/${parameters.getFacilityName()}/${parameters.getFacilityId()}/${parameters.getReceiverId()}`;
    router.navigate([redirectUrl]);
    return false;
  }

  return true;
};
