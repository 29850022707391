import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { EventBusService } from "./common/services/event-bus.service";
import { AuthService } from "./common/services/auth.service";
import { ParametersService } from "./common/services/parameters.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
	title = "ease-web-receiver";
	private eventBusSub?: Subscription;

	constructor(
		private readonly authService: AuthService,
		private readonly eventBusService: EventBusService,
		private readonly parameters: ParametersService
	) { }

	ngOnInit(): void {
		this.eventBusSub = this.eventBusService.on("logout", () => {
			this.logout();
		});
	}

	logout() {
		let receiverID: string | null = this.parameters.getReceiverId();
		if (receiverID === null) {
			return;
		}
		console.log(receiverID);
		this.authService.logout(receiverID);
	}

	ngOnDestroy(): void {
		this.eventBusSub?.unsubscribe();
	}
}
