import { Injectable } from '@angular/core';
import { ParametersService } from './parameters.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IReceiverData } from 'src/app/common/services/interfaces/ireceiver-data';

/**
  * Resolves the route parameters and sets them in the parameters service.
  * @param route - The activated route snapshot.
  * @param state - The router state snapshot.
  */
@Injectable({
  providedIn: 'root'
})
export class ParamResolverService {
  constructor(private parametersService: ParametersService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    const facilityName = route.paramMap.get('facilityName');
    const facilityId = route.paramMap.get('facilityId');
    const receiverId = route.paramMap.get('receiverId');
    const messageId = route.paramMap.get('messageId');
    const procedureId = route.paramMap.get('procedureId');

    if (receiverId) {
      this.parametersService.setReceiverId(receiverId);
    }

    let receiverData: IReceiverData | null = this.parametersService.getReceiverData();

    if (receiverData === null) {
      receiverData = {
        facilityId: null,
        facilityName: '',
        procedureId: null,
        patientGivenName: '',
        patientFamilyName: '',
        surveySubmitted: false
      };
    }

    if (facilityName) {
      receiverData.facilityName = facilityName;
    }

    if (facilityId) {
      receiverData.facilityId = (facilityId);
    }

    if (messageId) {
      this.parametersService.setNewMessageId(parseInt(messageId));
    }

    if (procedureId) {
     receiverData.procedureId = parseInt(procedureId);
    }
    
    this.parametersService.setReceiverData(receiverData);
  }
}
